import React, { useState } from 'react';
import axios from 'axios';

function Home() {
  const [houseNumber, setHouseNumber] = useState('');
  const [postcode, setPostcode] = useState('');
  const [epcData, setEpcData] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Use environment variable for API URL, with a fallback for development
      const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
      const response = await axios.get(`${apiBaseUrl}/api/epc/${encodeURIComponent(houseNumber)}/${encodeURIComponent(postcode)}`);
      setEpcData(response.data);
      setError(null); // Reset error
    } catch (error) {
      setError(error.message);
      console.error('Error in handleSubmit:', error); // Log error details
    }
  };

  return (
    <div>
      <h1>Get EPC Data</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={houseNumber}
          onChange={(e) => setHouseNumber(e.target.value)}
          placeholder="Enter house number"
          required
        />
        <input
          type="text"
          value={postcode}
          onChange={(e) => setPostcode(e.target.value)}
          placeholder="Enter postcode"
          required
        />
        <button type="submit">Submit</button>
      </form>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {epcData && (
        <div>
          <h2>EPC Data:</h2>
          <pre>{JSON.stringify(epcData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default Home;
