import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Table, Container, Form, Button, Accordion, Row, Col } from 'react-bootstrap';

function LeadsList() {
  const [leads, setLeads] = useState([]);
  const [filters, setFilters] = useState({
    main_heating_control: '',
    property_type: '',
    floor_description: '',
    built_form: '',
    windows_description: '',
    total_floor_area: '',
    roof_description: '',
    posttown: '',
    current_energy_rating: '',
    potential_energy_rating: '',
    main_heating_controls: '',
    walls_description: '',
    hotwater_description: '',
    tenure: '',
    main_fuel: '',
    lodgement_date: ''
  });

  useEffect(() => {
    fetchLeads();
  }, []);

  const fetchLeads = async () => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
      const response = await axios.get(`${apiBaseUrl}/api/epc/leads`, {
        params: filters,
      });
      setLeads(response.data);
    } catch (error) {
      console.error('Error fetching leads:', error.message);
    }
  };

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleSearch = () => {
    if (filters.postcode && filters.radius) {
      // Call getCoordinatesAndFilter if both postcode and radius are provided
      getCoordinatesAndFilter();
    } else {
      fetchLeads();
    }
  };

  const handleReset = () => {
    setFilters({
      main_heating_control: '',
      property_type: '',
      floor_description: '',
      built_form: '',
      windows_description: '',
      total_floor_area: '',
      roof_description: '',
      posttown: '',
      current_energy_rating: '',
      potential_energy_rating: '',
      main_heating_controls: '',
      walls_description: '',
      hotwater_description: '',
      tenure: '',
      main_fuel: '',
      lodgement_date: ''
    });
    fetchLeads(); // Re-fetch leads with no filters
  };

  const getCoordinatesAndFilter = async () => {
    try {
      const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${filters.postcode}&key=${googleMapsApiKey}`;
  
      const geocodeResponse = await axios.get(geocodeUrl);
      const location = geocodeResponse.data.results[0].geometry.location;
  
      const lat = location.lat;
      const lng = location.lng;
  
      // Pass lat/lng and radius to backend for filtering
      const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
      const response = await axios.get(`${apiBaseUrl}/api/epc/leads`, {
        params: { lat, lng, radius: filters.radius },
      });
      setLeads(response.data);
    } catch (error) {
      console.error('Error getting postcode coordinates:', error.message);
    }
  };

  return (
    <Container>
      <h1 className="my-4">All Leads</h1>

      {/* Filter Accordion */}
      <Accordion className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Filter Leads</Accordion.Header>
          <Accordion.Body>
            <Form>
              <Row>
                {/* First Column */}
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Main Heating Control</Form.Label>
                    <Form.Control name="main_heating_control" value={filters.main_heating_control} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Property Type</Form.Label>
                    <Form.Control name="property_type" value={filters.property_type} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Floor Description</Form.Label>
                    <Form.Control name="floor_description" value={filters.floor_description} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Built Form</Form.Label>
                    <Form.Control name="built_form" value={filters.built_form} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Windows Description</Form.Label>
                    <Form.Control name="windows_description" value={filters.windows_description} onChange={handleChange} />
                  </Form.Group>
                </Col>

                {/* Second Column */}
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Total Floor Area</Form.Label>
                    <Form.Control name="total_floor_area" value={filters.total_floor_area} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Roof Description</Form.Label>
                    <Form.Control name="roof_description" value={filters.roof_description} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Post Town</Form.Label>
                    <Form.Control name="posttown" value={filters.posttown} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Current Energy Rating</Form.Label>
                    <Form.Control name="current_energy_rating" value={filters.current_energy_rating} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Potential Energy Rating</Form.Label>
                    <Form.Control name="potential_energy_rating" value={filters.potential_energy_rating} onChange={handleChange} />
                  </Form.Group>
                </Col>

                {/* Third Column */}
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Main Heating Controls</Form.Label>
                    <Form.Control name="main_heating_controls" value={filters.main_heating_controls} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Walls Description</Form.Label>
                    <Form.Control name="walls_description" value={filters.walls_description} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Hot Water Description</Form.Label>
                    <Form.Control name="hotwater_description" value={filters.hotwater_description} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Tenure</Form.Label>
                    <Form.Control name="tenure" value={filters.tenure} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Main Fuel</Form.Label>
                    <Form.Control name="main_fuel" value={filters.main_fuel} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Lodgement Date</Form.Label>
                    <Form.Control type="date" name="lodgement_date" value={filters.lodgement_date} onChange={handleChange} />
                  </Form.Group>
                </Col>
              </Row>
              <Button className="mt-3" onClick={handleSearch}>Search</Button>
              <Button variant="secondary" className="mt-3 ms-2" onClick={handleReset}>Reset</Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Leads Table */}
      <div className="table-responsive mt-4">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Housing Status</th>
              <th>Benefits</th>
              <th>Current Situation</th>
              <th>Gas Boiler</th>
              <th>Boiler Installation Year</th>
              <th>Entry Date</th>
              <th>Created At</th>
              <th>Floor Height</th>
              <th>Construction Age Band</th>
              <th>Potential Energy Rating</th>
              <th>Address3</th>
              <th>Main Heat Control Description</th>
              <th>Property Type</th>
              <th>Local Authority Label</th>
              <th>Mechanical Ventilation</th>
              <th>County</th>
              <th>Postcode</th>
              <th>Solar Water Heating Flag</th>
              <th>Constituency</th>
              <th>Floor Description</th>
              <th>Local Authority</th>
              <th>Built Form</th>
              <th>Windows Description</th>
              <th>Address1</th>
              <th>Constituency Label</th>
              <th>Total Floor Area</th>
              <th>Roof Description</th>
              <th>Number of Habitable Rooms</th>
              <th>Address2</th>
              <th>Post Town</th>
              <th>Main Fuel</th>
              <th>Main Heating Controls</th>
              <th>Flat Top Storey</th>
              <th>Current Energy Rating</th>
              <th>Second Heat Description</th>
              <th>Walls Environmental Efficiency</th>
              <th>Transaction Type</th>
              <th>Main Heat Description</th>
              <th>Lodgement Date</th>
              <th>Tenure</th>
              <th>Walls Description</th>
              <th>Hot Water Description</th>
            </tr>
          </thead>
          <tbody>
            {leads.map((lead) => (
              <tr key={lead._id}>
                <td>
                  <Link to={`/lead/${lead._id}`}>
                    {lead.firstname}
                  </Link>
                </td>
                <td>{lead.surname}</td>
                <td>{lead.email}</td>
                <td>{lead.phone}</td>
                <td>{lead.address.line1}, {lead.address.postcode}</td>
                <td>{lead.housing_status}</td>
                <td>{lead.benefits.join(', ')}</td>
                <td>{lead.current_situation}</td>
                <td>{lead.gas_boiler ? 'Yes' : 'No'}</td>
                <td>{lead.boiler_installation_year}</td>
                <td>{new Date(lead.entry_date).toLocaleDateString()}</td>
                <td>{new Date(lead.createdAt).toLocaleDateString()}</td>
                <td>{lead.floor_height}</td>
                <td>{lead.construction_age_band}</td>
                <td>{lead.potential_energy_rating}</td>
                <td>{lead.address3}</td>
                <td>{lead.mainheatcont_description}</td>
                <td>{lead.property_type}</td>
                <td>{lead.local_authority_label}</td>
                <td>{lead.mechanical_ventilation}</td>
                <td>{lead.county}</td>
                <td>{lead.postcode}</td>
                <td>{lead.solar_water_heating_flag}</td>
                <td>{lead.constituency}</td>
                <td>{lead.floor_description}</td>
                <td>{lead.local_authority}</td>
                <td>{lead.built_form}</td>
                <td>{lead.windows_description}</td>
                <td>{lead.address1}</td>
                <td>{lead.constituency_label}</td>
                <td>{lead.total_floor_area}</td>
                <td>{lead.roof_description}</td>
                <td>{lead.number_habitable_rooms}</td>
                <td>{lead.address2}</td>
                <td>{lead.posttown}</td>
                <td>{lead.main_fuel}</td>
                <td>{lead.main_heating_controls}</td>
                <td>{lead.flat_top_storey}</td>
                <td>{lead.current_energy_rating}</td>
                <td>{lead.secondheat_description}</td>
                <td>{lead.walls_env_eff}</td>
                <td>{lead.transaction_type}</td>
                <td>{lead.mainheat_description}</td>
                <td>{lead.lodgement_date}</td>
                <td>{lead.tenure}</td>
                <td>{lead.walls_description}</td>
                <td>{lead.hotwater_description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Container>
  );
}

export default LeadsList;
