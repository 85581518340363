import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import NotFound from './components/NotFound';
import LeadsList from './components/LeadsList';
import LeadDetails from './components/LeadDetails';
import Header from './components/Header';
import BulkUpload from './components/BulkUpload';

function App() {
  return (
    <Router>
      <div className="App">
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/leads" element={<LeadsList />} />
          <Route path="/lead/:id" element={<LeadDetails />} />
          <Route path="/bulk-upload" element={<BulkUpload />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;