import React, { useState } from 'react';
import axios from 'axios';

const CHUNK_SIZE = 5 * 1024 * 1024;  // 5MB per chunk

const BulkUpload = () => {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const uploadChunk = async (chunk, index, totalChunks) => {
    const formData = new FormData();
    formData.append('fileChunk', chunk);  // This must be 'fileChunk' to match Multer field name
    formData.append('chunkIndex', index);
    formData.append('totalChunks', totalChunks);
  
    await axios.post('/api/upload/bulk-upload-chunk', formData, {
      onUploadProgress: (progressEvent) => {
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      }
    });
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file to upload');
      return;
    }

    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

    for (let i = 0; i < totalChunks; i++) {
      const start = i * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, file.size);

      const chunk = file.slice(start, end);
      await uploadChunk(chunk, i, totalChunks);
    }

    alert('File uploaded successfully');
  };

  return (
    <div>
      <h2>Bulk Lead Upload</h2>
      <input type="file" onChange={handleFileChange} accept=".csv" />
      <button onClick={handleUpload}>Upload</button>
      {uploadProgress > 0 && <progress value={uploadProgress} max="100">{uploadProgress}%</progress>}
    </div>
  );
};

export default BulkUpload;
